<template>
  <v-main>
    <v-container fluid>
      <slot></slot>
    </v-container>
  </v-main>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description app-content-layout Component Logic
   * @date 28.07.2020
   */
  export default {
    name: "app-content-layout"
  };
</script>

<style scoped></style>
